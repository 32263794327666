import XHR from '@/request/http'
const $h = new XHR()
// url
const url = {
    lists:'/admin/v1/roles/list',
    create:'/admin/v1/roles/create',
    update:'/admin/v1/roles/update',
    del:'/admin/v1/roles/del',
    menus:'/admin/v1/roles/menus',
    
}

//
export async function lists(data)
{
    return $h.request(url.lists,data,'GET')
}

//
export async function create(data)
{
    return $h.request(url.create,data,'POST')
}

export async function update(data)
{
    return $h.request(url.update,data,'POST')
}

export async function del(data)
{
    return $h.request(url.del,data,'POST')
}

//编辑权限
export async function menus(data)
{
    return $h.request(url.menus,data,'POST')
}


